<template>
  <div>
    <XNav :content="[{ t: 'common.faq' }]"/>
    <div class="faq">
      <p class="bold">{{ $t("pug.faq.q1") }}</p>
      <p class="text">{{ $t("pug.faq.a1") }}</p>
      <p class="bold">{{ $t("pug.faq.q2") }}</p>
      <p class="text">{{ $t("pug.faq.a2") }}</p>
      <p class="bold">{{ $t("pug.faq.q3") }}</p>
      <p class="text">{{ $t("pug.faq.a3") }}</p>
      <p class="bold">{{ $t("pug.faq.q4") }}</p>
      <p class="text">{{ $t("pug.faq.a4") }}</p>
      <p class="bold">{{ $t("pug.faq.q5") }}</p>
      <p class="text">{{ $t("pug.faq.a5") }}</p>
      <p class="text">{{ $t("pug.faq.a5a") }}</p>
      <p class="text">{{ $t("pug.faq.a5b") }}</p>
      <p class="bold">{{ $t("pug.faq.q5") }}</p>
      <p class="text">{{ $t("pug.faq.a5") }}</p>
      <p class="bold">{{ $t("pug.faq.q6") }}</p>
      <p class="text">{{ $t("pug.faq.a6") }}</p>
      <p class="bold">{{ $t("pug.faq.q7") }}</p>
      <p class="text">{{ $t("pug.faq.a7") }}</p>
      <p class="bold">{{ $t("pug.faq.q8") }}</p>
      <p class="text">{{ $t("pug.faq.a8") }}</p>
      <p class="bold">{{ $t("pug.faq.q9") }}</p>
      <p class="text">{{ $t("pug.faq.a9") }}</p>
      <p class="bold">{{ $t("pug.faq.q10") }}</p>
      <p class="text">{{ $t("pug.faq.a10") }}</p>
    </div>
  </div>  
</template>

<script>
import XNav from "@/components/XNav.vue";

export default {
  name: "PUG-FAQ",
  components: {
    XNav,
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

  .bold {
    @extend %font-bold;
    margin-top: 10px;
    font-size: 18px;
  }

  .text {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 16px;
  }


  @media only screen and (max-width: 600px) {
    .faq {
      width: 70vw;
      min-width: initial;
    }

    .bold {
      font-size: 16px;
    }

    .text {
      font-size: 14px;
    }
  }
</style>